// Config file and project variables

// ----------
// Fonts zone
// ----------

// Font families
$font-family-base      : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !default; // system font stack
$font-family-headings  : -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif !default; // font for h1, h2.. h6
$font-family-monospace : consolas, courier, monospace !default; // font for code and samples

// Font sizes
$font-size-base : 1.4rem !default;

$font-sizes: (
        base: 1.4rem,
        h1: 3.8rem,
        h2: 3rem,
        h3: 1.8rem,
        h4: 1.4rem,
        h5: 1.2rem,
        h6: 1rem,
) !default;

// Line heights
$line-height-s    : 1.3 !default;
$line-height-base : 1.5 !default;
$line-height-l    : 1.7 !default;

// Default margin-bottom
$margin-bottom-base     : 1rem !default;
$headings-margin-bottom : $margin-bottom-base /2 !default;
$paragraph-margin-bottom: $margin-bottom-base !default;

// Font weights
$weight-light   : 200 !default;
$weight-book    : 300 !default;
$weight-regular : 400 !default;
$weight-medium  : 500 !default;
$weight-bold    : 700 !default;

// Activate hyphenation on small screens
$hyphens: false !default;

// ------------
// Spacing zone
// ------------

// Number of grid-columns
$cols: 12 !default;

// Gutter
$gutter: null;

// Grid gutters (for .has-gutter-* classes)
$grid-gutters: (
        '': 1rem,
        '-l': 2rem,
        '-xl': 4rem
) !default;

// Spacings
$spacer-tiny             : .5rem  !default;
$spacer-tiny-plus        : .7rem !default;
$spacer-small            : 1rem !default;
$spacer-small-plus       : 1.5rem !default;
$spacer-medium           : 2rem !default;
$spacer-medium-plus      : 3rem !default;
$spacer-large            : 4rem !default;
$spacer-large-plus       : 6rem !default;
$spacer-extra-large      : 8rem !default;
$spacer-extra-large-plus : 12rem !default;
$spacer-ultra-large      : 16rem !default;
$spacer-ultra-large-plus : 20rem !default;

// ----------
// Color zone
// ----------

// Color names
$white    : #fff !default;
$gray-100 : #f8f9fa !default;
$gray-200 : #e7e9ed !default;
$gray-300 : #dee2e6 !default;
$gray-400 : #ced4da !default;
$gray-500 : #acb3c2 !default;
$gray-600 : #727e96 !default;
$gray-700 : #454d5d !default;
$gray-800 : #333 !default;
$gray-900 : #212529 !default;
$black    : #000 !default;

$purple: #310471 !default;

// Semantic colors
$color-brand    : $purple !default;
$color-inverse  : $gray-800 !default;
$color-ghost    : transparent !default;
$color-muted    : $gray-200 !default;

$color-base      : $gray-900 !default;
$background-base : $white !default;

$forms-color     : $gray-800 !default;

// ---------------
// Components zone
// ---------------

// Component: links
$link-color            : $gray-800 !default;
$link-color-hover      : darken($link-color, 15%) !default;
$link-decoration       : underline !default;
$link-decoration-hover : underline !default;

// Global border-radius
$border-radius: 0 !default;

// Component: quotes
$quote-color     : $gray-200 !default;
